import styled from "@emotion/styled"
import banner from '../images/banner.jpg'
import { StaticQuery, graphql } from "gatsby"
import Img, { FluidObject } from 'gatsby-image'
import { css } from '@emotion/react'

const Image = ({ children }: { children: (fluid: FluidObject) => JSX.Element }) => (
  <StaticQuery
    query={graphql`query MyQuery {
      file(relativePath: {eq: "banner.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 90) {
            src
            sizes
            srcSet
            aspectRatio
            presentationWidth
          }
        }
      }
    }`}
    render={data => children(data.file.childImageSharp.fluid)} />
)

const imgCss = css`
  height: 100%;
  width: 100%;
`

export const HeaderContainer = styled.div`
  height: 500px;
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  box-shadow: 2px 2px 15px 2px rgba(0,0,0,0.2);
  margin-top: 20px;
  border-radius: 5px;
  overflow: hidden;
`

export const HeaderTitle = styled.div`
  position: absolute;
  bottom: 10px;
  left: 20px;
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.1;

  @media (max-width: 768px) {
    font-size: 4rem;
  }
`

export const HeaderImage = (props) => (
  <Image>
    {(fluid) => (
      <Img fluid={fluid} css={imgCss} />
    )}
  </Image>
)