import axios from 'axios'
import { rsvpEndpoint } from '../utils/constants'

let guestId = 0;

export type RsvpFields = {
    name: string,
    food: string,
    foodError: string,
    id?: number,
    isChild: boolean,
    guests: RsvpFields[],
    dietaryRestrictions: string,
    attendingRehearsal: boolean,
    invitedToRehearsal: boolean
}
  
export type RsvpResult = {
    name: string,
    guests: string[],
    children: string[],
    rehearsalInvite: boolean
}

export default class Rsvp {
    static rsvpValidator(rsvp: RsvpFields): boolean {
        let hasError = false
    
        if (!rsvp.food || rsvp.food.length <= 0) {
        rsvp.foodError = 'Please select a food option or you\'ll go hungry'
        hasError = true;
        }
    
        if (hasError) {
        return false
        } 
        
        rsvp.foodError = null
        return true
    }

    static async searchRsvp(search: string): Promise<{ rsvp: RsvpFields, searchError: string }> {
        let { data: result }: { data: RsvpResult } = await axios.get(rsvpEndpoint, { params: { search } });
        let rsvp: RsvpFields = null
        let searchError = ''
        
        if (result.name) {
            rsvp = {
                name: result.name,
                food: '',
                foodError: null,
                isChild: false,
                guests: result.guests.map(g => ({
                name: g,
                food: '',
                foodError: null,
                id: guestId++,
                guests: [],
                isChild: false,
                dietaryRestrictions: '',
                attendingRehearsal: false,
                invitedToRehearsal: result.rehearsalInvite
                })).concat(result.children.map(c => ({
                name: c,
                food: '',
                foodError: null,
                id: guestId++,
                guests: [],
                isChild: true,
                dietaryRestrictions: '',
                attendingRehearsal: false,
                invitedToRehearsal: result.rehearsalInvite
                }))),
                dietaryRestrictions: '',
                attendingRehearsal: false,
                invitedToRehearsal: result.rehearsalInvite
            }
        } else {
            searchError = 'An RSVP with your name could not be found'
        }

        return {
            rsvp,
            searchError
        }
    }

    static async sendRsvp(rsvps: RsvpFields[]): Promise<any> {
        let rsvpNoGuests = rsvps.map(({ guests, ...rest }) => ({ ...rest }))
        return axios.post(rsvpEndpoint, { rsvps:  rsvpNoGuests })
    }
}