import styled from "@emotion/styled"
import { CountdownTransitionProps } from "./countdown"

export const Content = styled.div`
  display: ${(props: CountdownTransitionProps) => props.contentVisible ? 'block' : 'none'};
  width: 100%;
`

export const ContentCenteredColumn = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props: CountdownTransitionProps) => props.contentVisible ? 'flex-start' : 'center'};
  flex-direction: column;
  padding-bottom: 80px;
  width: 100%;

  ${Content}:last-child {
    padding-bottom: 100px;
  }

  @media (min-width: 768px) and (max-resolution: 1.9dppx) {
    width: 50%;
  }

  @media (min-resolution: 2dppx) and (min-width: 1536px) {
    width: 50%;
  }

  @media (min-resolution: 2dppx) and (min-width: 1024px) and (max-width: 1535px) {
    width: 80%;
  }

  @media (max-width: 768px) and (max-resolution: 1.9dppx) {
    padding: 10px;
  }

  @media (max-width: 1024px) and (min-resolution: 2dppx) {
    padding: 10px;
  }
`

export const ContentHeader = styled.div`
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
`