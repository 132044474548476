export const ceremonyCalendar = `http://www.google.com/calendar/event?action=TEMPLATE&dates=20191026T203000Z%2F20191027T050000Z&text=Christy%20%26%20Jason's%20Wedding&location=901%20Lawrence%20Ave%20W%20North%20York%2C%20ON%20M6A%201C3%20Canada&details=`

export const columbusEventCenter = `https://www.google.com/maps/place/Columbus+Event+Centre/@43.7131126,-79.4589279,17z/data=!3m1!4b1!4m5!3m4!1s0x882b3302c8757a4f:0x89d697d52fb2eee7!8m2!3d43.7131087!4d-79.4567392`

export const rsvpEndpoint = `https://u1p7uu88d3.execute-api.us-east-1.amazonaws.com/prod/rsvp`

export const rehearsalText = "We appreciate you travelling to Toronto for our wedding and would love to invite you to a casual get-together dinner on Friday October 25 at 7:00pm. The dinner will be close to the venue, but location is currently TBD (possibly in Richmond Hill). Absolutely no pressure to attend but feel free to join if you're available!"

export const dimsumLocation = "https://www.google.com/maps/place/Kennedy+Rd+%26+14th+Ave,+Markham,+ON+L3R+0A7,+Canada/data=!4m2!3m1!1s0x89d4d43c5187d2af:0x6058abb160cd2a14?sa=X&ved=2ahUKEwippczI38jjAhUDZKwKHVOUCmkQ8gEwAHoECAsQAQ"

export const marriottMarkham = "https://www.marriott.com/hotels/travel/yyzmr-toronto-marriott-markham/"