import styled from "@emotion/styled"

export const DetailContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

export const Detail = styled.div`
  flex-grow: 1;
  min-width: 300px;
  text-align: center;
  margin-top: 10px;
`

export const DetailImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-image: url(${(props: { image: string }) => props.image});
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
`

export const DetailText = styled.div`
    text-align: left;
    padding: 15px;
`