import styled from "@emotion/styled"
import React from 'react'
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Tooltip from "@material-ui/core/Tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { rehearsalText } from "../utils/constants"
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { RsvpFields } from "../utils/rsvp";

export const RsvpContainer = styled.div`
  text-align: center;
  height: 80px;
  padding: 10px 0;
  width: 100%;
  background: transparent;
  position: fixed;
  bottom: 0;
  left: 0;
`

export const RsvpButton = styled.button`
  border-radius: 10px;
  border: solid 1px rgba(0,0,0,0.5);
  font-size: 1.5rem;
  padding: 5px 20px;
  color: black;
  cursor: pointer;
  background-color: white;
  animation: float 2.5s ease-in-out infinite;
  box-shadow: 0 5px 15px 0px rgba(0,0,0,0.4);
	transform: translatey(0px);
  
  :focus {
    outline: 0;
  }

  :hover {
    animation: none;
  }
`

const RehearsalDinner = () => <span>Attending rehearsal dinner&nbsp;<Tooltip title={rehearsalText}><span><FontAwesomeIcon icon={faInfoCircle} /></span></Tooltip></span>

type RsvpFormProps = {
  rsvp: RsvpFields,
  foodChanged: (rsvp: RsvpFields, value: string) => void;
  attendingRehearsalChanged: (rsvp: RsvpFields, attending: boolean) => void;
}

const RsvpForm = ({ rsvp, foodChanged, attendingRehearsalChanged }: RsvpFormProps) => (
  <>
    <span>{rsvp.name}</span>
    <TextField
      select
      label="Select an Option"
      value={rsvp.food}
      margin="dense"
      fullWidth
      onChange={e => foodChanged(rsvp, e.target.value)}
      error={!!rsvp.foodError}
      helperText={rsvp.foodError}
    >
      { !rsvp.isChild && 
        <MenuItem value='beef'>
          Filet Mignon
        </MenuItem> }
      { !rsvp.isChild &&
        <MenuItem value='fish'>
          Grilled Salmon
        </MenuItem> }
      { rsvp.isChild &&
        <MenuItem value='child'>
          Child (Chicken Fingers + Fries)
        </MenuItem> }
      { rsvp.isChild &&
         <MenuItem value='infant'>
          Infant (Pasta)
        </MenuItem> }
      { !rsvp.isChild &&
        <MenuItem value='vege'>
          Vegetarian
        </MenuItem> }
      { !rsvp.isChild && 
        <MenuItem value='vegan' disabled={rsvp.isChild}>
            Vegan
        </MenuItem> }
      <MenuItem value='not-attending'>
        Not Attending
      </MenuItem>
    </TextField>
    { rsvp.invitedToRehearsal &&
      <FormControlLabel 
        control={<Checkbox
          checked={rsvp.attendingRehearsal}
          color="default"
          value="checkedG"
          disableRipple
          onChange={e => attendingRehearsalChanged(rsvp, e.target.checked)}
          disabled={rsvp.food == 'not-attending'}
        />}
        label={<RehearsalDinner />}
      /> }
  </>
)

export default RsvpForm