import React, { Component } from "react"
import moment from "moment"
import styled from "@emotion/styled";

const WEDDING_DATE = moment('2019-10-26T00:00:00-04:00')
const DAY_SECONDS = 24 * 60 * 60
const HOUR_SECONDS = 60 * 60
const MINUTE_SECONDS = 60

export type CountdownTransitionProps = {
    contentVisible: boolean
}

export const CountdownTransition = styled.div`
    font-weight: 700;
    transition: transform 0.2s linear;

    @media (min-width: 768px) {
    transform: ${(props: CountdownTransitionProps) => props.contentVisible ? 'scale(2.5)' : 'scale(3.5)'};
    }

    @media (max-width: 768px) {
    transform: ${(props: CountdownTransitionProps) => props.contentVisible ? 'scale(1.2)' : 'scale(1.5)'};
    }
`

export default class Countdown extends Component<{}, { days: number, hours: number, minutes: number, seconds: number, isAfter: boolean }> {
    constructor(props) {
        super(props)

        let { dayDiff, hourDiff, minuteDiff, secondDiff, isAfter } = this.getTime()

        this.state = {
            days: dayDiff,
            hours: hourDiff,
            minutes: minuteDiff,
            seconds: secondDiff,
            isAfter
        }
    }

    componentDidMount() {
        this.updateTime()
    }

    updateTime = () => {
        let { dayDiff, hourDiff, minuteDiff, secondDiff } = this.getTime()

        this.setState({
            days: dayDiff,
            hours: hourDiff,
            minutes: minuteDiff,
            seconds: secondDiff
        })

        setTimeout(() => this.updateTime(), 100)
    }

    getTime = () => {
        let now = moment()
        let isAfter = now.isAfter(WEDDING_DATE);
        let diff = isAfter ? now.diff(WEDDING_DATE, 'seconds') :  WEDDING_DATE.diff(now, 'seconds')
        let dayDiff = Math.floor(diff / DAY_SECONDS)
        let hourDiff = Math.floor((diff - dayDiff * DAY_SECONDS) / HOUR_SECONDS)
        let minuteDiff = Math.floor((diff - dayDiff * DAY_SECONDS - hourDiff * HOUR_SECONDS) / MINUTE_SECONDS)
        let secondDiff = diff - dayDiff * DAY_SECONDS - hourDiff * HOUR_SECONDS - minuteDiff * MINUTE_SECONDS
        
        return {
            dayDiff,
            hourDiff,
            minuteDiff,
            secondDiff,
            isAfter
        }
    }

    render() {
        let { days, hours, minutes, seconds, isAfter } = this.state

        return (
            <div>
                { !isAfter ?
                    `${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`
                    : <div style={{ textAlign: "center" }}>{ `${Math.abs(days)} days ${Math.abs(hours)} hours ${Math.abs(minutes)} minutes ${Math.abs(seconds)} seconds`} <br /> {` since our wedding`}</div> }
            </div>
        )
    }
}