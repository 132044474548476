import { Portal } from 'semantic-ui-react'
import React, { Component, createRef, RefObject } from "react"
import { faAngleLeft, faTimes, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from '@emotion/styled'
import Img, { FluidObject } from 'gatsby-image'
import { css } from '@emotion/react'
import GatsbyImage from 'gatsby-image';

export enum LightboxType {
    Jpeg,
    Png,
}

const muted = "#d2d2d2";

const LightboxContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${(props: { withBackdrop: boolean }) => props.withBackdrop ?  "rgba(0, 0, 0, 0.8)" : "transparent"};
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${muted};
    z-index: 5;
`

const LightboxNav = styled.div`
    height: 100%;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: ${(props: { visible: boolean }) => props.visible ? "visible" : "hidden"}
`

const LightboxIconWrapper = styled.span`
    cursor: pointer;
`

interface FluidImage extends FluidObject {
    presentationWidth: number;
    caption?: string;
}

type LightboxImage = {
    fluid: FluidImage;
    type: LightboxType;
    caption?: string;
}

type LightboxProps = {
    images: LightboxImage[];
    open: boolean;
    onClose?: () => void;
    onClickNext?: () => void;
    onClickPrev?: () => void;
    currentImage: number;
    withBackdrop?: boolean;
}

const imgCss = css`
    margin: 0 auto;
    flex-grow: 1;
    max-height: 100%;
    transition: opacity 0.3s linear;
`

const LightboxCaption = styled.div`
    margin-top: 5px;
    opacity: 0;
    transition: opacity 0s linear;
    transition-delay: 0.1s;
`

class LightboxImageComponent extends Component<{ img: FluidImage, innerRef: RefObject<GatsbyImage>, imageLoaded: () => void }, { loaded: boolean }> {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false
        }
    }

    imgLoaded = () => {
        if (this.state.loaded) {
            return
        }
        this.props.imageLoaded()
        setTimeout(() => this.setState({ loaded: true }), 100)
    }

    render() {
        let { img, innerRef } = this.props
        let { loaded } = this.state

        return <Img fluid={img} css={imgCss} style={{ maxWidth: img.presentationWidth, opacity: loaded? 1 : 0 }} imgStyle={{ objectFit: "contain" }} onLoad={this.imgLoaded} ref={innerRef} />
    }
}

export default class Lightbox extends Component<LightboxProps, { mounted: boolean, currentIndex: number }> {
    imgRef = createRef<GatsbyImage>()
    captionRef = createRef<HTMLDivElement>()
    containerRef = createRef<HTMLDivElement>()

    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
            currentIndex: 0
        };
    }

    componentDidUpdate(prevProps: LightboxProps) {
        if (!this.containerRef.current) {
            return
        }

        this.containerRef.current.focus()
        if (!prevProps.open && this.props.open) {
            this.containerRef.current.addEventListener('keydown', this.keydown)
        } else if (prevProps.open && !this.props.open) {
            this.containerRef.current.removeEventListener('keydown', this.keydown)
        }
    }

    keydown = (e: KeyboardEvent) => {
        if (e.keyCode == 39 && this.canNext()) {
            this.nextClick()
        } else if (e.keyCode == 37 && this.canPrev()) {
            this.prevClick()
        }
    }

    imageLoaded = () => {
        let currentImgRef = this.imgRef.current as any

        if (currentImgRef && currentImgRef.imageRef.current && this.captionRef.current) {
            let [width] = getContainedSize(currentImgRef.imageRef.current)
            this.captionRef.current.style.maxWidth = `${width}px`;
            this.captionRef.current.style.opacity = '1'
        }
    }

    onMount = () => {
        this.setState({ mounted: true, currentIndex: this.props.currentImage });
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    canNext() {
        return this.props.images.length > 1 && !!this.props.images[this.state.currentIndex + 1];
    }

    canPrev() {
        return this.state.currentIndex > 0;
    }

    nextClick = () => {
        if (this.props.onClickNext) {
            this.props.onClickNext();
        }
        this.setState({ currentIndex: this.state.currentIndex + 1 })
    }

    prevClick = () => {
        if (this.props.onClickPrev) {
            this.props.onClickPrev();
        }
        this.setState({ currentIndex: this.state.currentIndex - 1 })
    }

    render() {
        let { open, images, withBackdrop = true } = this.props,
            { currentIndex } = this.state,
            currentLightboxImage = images[currentIndex];

        return currentLightboxImage ?
            <Portal open={open} onClose={this.onClose} closeOnDocumentClick={true} onMount={this.onMount}>
                <>
                    <LightboxContainer withBackdrop={withBackdrop} ref={this.containerRef} tabIndex={-1}>
                        <LightboxIconWrapper onClick={this.onClose}>
                            <FontAwesomeIcon icon={faTimes} size="2x" color='white' style={{ right: 20, top: 20, position: 'absolute' }} />
                        </LightboxIconWrapper>
                        <LightboxNav visible={this.canPrev()}>
                            <LightboxIconWrapper onClick={this.prevClick}><FontAwesomeIcon icon={faAngleLeft} size="3x" /></LightboxIconWrapper>
                        </LightboxNav>
                        <div style={{ flexGrow: 1, display: "flex", justifyContent: "center", flexWrap: 'wrap' }}>
                            <div style={{ position: "relative", maxHeight: "calc(100vh - 150px)", width: "calc(100vw - 100px)", display: 'flex', alignItems: 'center' }}>
                                { images.map(({ fluid }, i) => i == currentIndex ? <LightboxImageComponent img={fluid} key={i} innerRef={this.imgRef} imageLoaded={this.imageLoaded} /> : null) }
                            </div>
                            { images.map(({ caption }, i) => i == currentIndex ? <LightboxCaption key={i} ref={this.captionRef}>{caption}</LightboxCaption> : null)}
                        </div>
                        <LightboxNav visible={this.canNext()}>
                            <LightboxIconWrapper onClick={this.nextClick}><FontAwesomeIcon icon={faAngleRight} size="3x" /></LightboxIconWrapper>
                        </LightboxNav>
                    </LightboxContainer>
                </>
            </Portal> :
            <>
            </>
    }
}

function getContainedSize(img) {
    let ratio = img.naturalWidth/img.naturalHeight
    let width = img.height*ratio
    let height = img.height
    if (width > img.width) {
        width = img.width
        height = img.width/ratio
    }
    return [width, height]
}