import React, { Component, ComponentType } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Countdown, { CountdownTransition } from '../components/countdown'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock, faMapMarkerAlt, faSearch, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import hotel from '../images/marriott.jpg'
import dimsum from '../images/dimsum.jpg'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Zoom from '@material-ui/core/Zoom'
import TextField from '@material-ui/core/TextField'
import { ToastConsumer, ToastProvider } from 'react-toast-notifications'
import Tooltip from '@material-ui/core/Tooltip'
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { MuiThemeProvider } from '@material-ui/core/styles'
import Photos from '../components/photos'
import Lightbox, { LightboxType } from '../components/lightbox'
import ReactGA, { sendEvent } from '../components/ga'
import { ceremonyCalendar, columbusEventCenter, dimsumLocation, marriottMarkham } from '../utils/constants'
import RsvpForm, { RsvpContainer, RsvpButton } from '../components/rsvp'
import Rsvp, { RsvpFields } from '../utils/rsvp'
import Rule from '../components/hr'
import { DetailContainer, Detail, DetailImage, DetailText } from "../components/detail"
import { HeaderImage, HeaderTitle, HeaderContainer } from "../components/header"
import { ContentCenteredColumn, Content, ContentHeader } from "../components/content"
import theme from '../utils/theme'

const Transition = React.forwardRef((props, ref) => {
  return <Zoom ref={ref} {...props} />;
});

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  overflow: auto;
`

const Wedding = styled.div`
  width: 300px;
  margin-top: 10px;
`

const Anchor = styled.a`
  color: black;
  cursor: pointer;
  text-decoration: underline;
  transition: opacity 0.2s linear;

  :hover {
    opacity: 0.7;
  }
`

const GuestContainer = styled.div`
  margin-top: 25px;
`

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
`

const ClickableImg = styled.img`
  cursor: pointer;
  transition: transform 0.15s linear;

  :hover {
    transform: scale(1.01);
  }
`

class Index extends Component<{}, { contentVisible: boolean, rsvpOpen: boolean, rsvp: RsvpFields, search: string, loading: boolean, searchError: string, saving: boolean, lightboxOpen: boolean, selectedPhotoIndex: number }> {
  constructor(props) {
    super(props)

    this.state = {
      contentVisible: false,
      rsvpOpen: false,
      rsvp: null,
      search: '',
      loading: false,
      searchError: '',
      saving: false,
      lightboxOpen: false,
      selectedPhotoIndex: 0
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({ contentVisible: true }), 3000)
  }

  toggleRsvp = () => this.setState({ rsvpOpen: !this.state.rsvpOpen, rsvp: null, search: '' })

  foodChanged = (model: RsvpFields, value: string) => {
    let { rsvp } = this.state
    model.food = value
    this.setState({ rsvp })
  }

  dietaryRestrictionsChanged = e => {
    let { rsvp } = this.state
    rsvp.dietaryRestrictions = e.target.value

    this.setState({ rsvp })
  }

  attendingRehearsalChanged = (model: RsvpFields, checked: boolean) => {
    let { rsvp } = this.state
    model.attendingRehearsal = checked

    this.setState({ rsvp })
  }

  closeLightbox = () => {
    this.setState({ lightboxOpen: false })
  }

  openLightbox = (index: number) => {
    sendEvent('image', 'view')
    this.setState({ lightboxOpen: true, selectedPhotoIndex: index })
  }

  nextImage = () => {
    sendEvent('image', 'next')
    this.setState({ selectedPhotoIndex: this.state.selectedPhotoIndex + 1 })
  }
  prevImage = () => {
    sendEvent('image', 'prev')
    this.setState({ selectedPhotoIndex: this.state.selectedPhotoIndex - 1})
  }

  searchChanged = e => this.setState({ search: e.target.value })

  sendRsvp = async (toast) => {
    let { rsvp } = this.state

    if (!rsvp) {
      return
    }

    let rsvps = [rsvp, ...rsvp.guests]

    if (rsvps.every(r => Rsvp.rsvpValidator(r))) {
      // send results
      this.setState({ saving: true })
      sendEvent('rsvp', 'send')

      try {
        await Rsvp.sendRsvp(rsvps)
        toast(`Your RSVP has been sent.`, { appearance: 'success' })
        this.setState({ rsvpOpen: false, saving: false })
      } catch {
        sendEvent('rsvp', 'error')
        toast(`We couldn't save your RSVP. Contact Jason to fix it.`)
        this.setState({ saving: false })
      }
    }

    this.setState({ rsvp })
  }

  searchRsvp = async e => {
    e.preventDefault()
    
    if (this.state.search.length <= 3 || this.state.loading) {
      this.setState({ searchError: 'Enter a minimum of 3 characters' })
     return
    }

    this.setState({ loading: true, searchError: '' })

    try {
      let { rsvp, searchError} = await Rsvp.searchRsvp(this.state.search)
      this.setState({ rsvp, loading: false, searchError })
    } catch {
      this.setState({ loading: false })
    }
  }

  render() {
    let { contentVisible, rsvpOpen, rsvp, search, searchError, loading, saving, selectedPhotoIndex, lightboxOpen } = this.state

    return (
      <>
        <Layout>
          <MuiThemeProvider theme={theme}>
            <SEO title="Wedding" lang="en" />
            <Container>
              <ContentCenteredColumn contentVisible={contentVisible}>
                <Content contentVisible={contentVisible}>
                  <HeaderContainer>
                    <HeaderImage />
                    <HeaderTitle>
                      <span style={{ color: 'white' }}>Christy</span> + <span style={{ color: '#2b2b2b' }}>Jason</span>
                    </HeaderTitle>
                  </HeaderContainer>
                </Content>
                <CountdownTransition contentVisible={contentVisible}>
                  <Countdown />
                </CountdownTransition>
                <Rule contentVisible={contentVisible} />
                <Content contentVisible={contentVisible}>
                  <ContentHeader>
                    Wedding Details
                  </ContentHeader>
                  <DetailContainer>
                    <Wedding>
                      <div style={{ lineHeight: '1.7', fontSize: '1.2rem' }}>
                        <FontAwesomeIcon icon={faCalendar} />&nbsp;<Tooltip title='Add to calendar' placement='right'><Anchor href={ceremonyCalendar} target='_blank'>Saturday, October 26, 2019</Anchor></Tooltip><br />
                        <FontAwesomeIcon icon={faClock} />&nbsp;4:30 PM<br />
                        <FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;<Tooltip title='Click for directions' placement='right'><Anchor href={columbusEventCenter} target='_blank'>Columbus Event Centre<br />901 Lawrence Ave W<br />North York, ON M6A 1C3<br />Canada</Anchor></Tooltip><br /><br />
                        Drinks! Dinner! Dancing! To Follow!
                      </div>
                    </Wedding>
                  </DetailContainer>
                </Content>
                <Rule contentVisible={contentVisible} />
                <Content contentVisible={contentVisible}>
                  <ContentHeader>
                    Accommodations
                  </ContentHeader>
                  <DetailContainer>
                    <Detail>
                      <DetailImage image={hotel} />
                    </Detail>
                    <Detail>
                      <DetailText>
                      Here are a couple options that we've researched for you:<br/><br/>

                      <strong>Holiday Inn Yorkdale</strong><br/>
                      The <Anchor href="http://www.hiyorkdale.com/" target="_blank">Holiday Inn Yorkdale</Anchor> is the closest full-service hotel to the venue. Rates start at $237.60 CAD per night.<br/><br/>

                      <strong>AirBnB</strong><br/>
                      There are a whole bunch of listings at a new condo building about a 12-minute walk from the wedding venue. Christy and Jason will be staying in a unit there the night of the wedding. <Anchor href="https://www.airbnb.ca/rooms/36119964" target="_blank">Here</Anchor>, <Anchor href="https://www.airbnb.ca/rooms/34392632" target="_blank">here</Anchor>, <Anchor href="https://www.airbnb.ca/rooms/32197459" target="_blank">here</Anchor>, <Anchor href="https://www.airbnb.ca/rooms/34782910" target="_blank">here</Anchor>, and <Anchor href="https://www.airbnb.ca/rooms/24749302" target="_blank">here</Anchor> are some other units in the same building with availability on that weekend.<br/><br/>

                      <strong>Marriott Markham</strong><br/>
                      Christy and Jason are fairly avid Marriott Bonvoy collectors, so we would be remiss if we didn't include a Marriott option. The <Anchor href={marriottMarkham} target="_blank">Marriott Markham</Anchor> is a bit of a trek from the venue (about a $40 CAD Uber ride) but it's brand new and gorgeous. We have a block of rooms reserved at a discounted rate of $149 CAD per night, plus it's close to post-wedding dimsum on Sunday afternoon. Click <Anchor href="https://www.marriott.com/events/start.mi?id=1560945940106&key=GRP" target="_blank">here</Anchor> to book with the special rate or you can call 905-489-1400 and reference the Chak/Gao party at the Marriott Markham. This rate is only available until Tuesday September 10th.
                      </DetailText>
                    </Detail>
                  </DetailContainer>
                </Content>
                <Rule contentVisible={contentVisible} />
                <Content contentVisible={contentVisible}>
                  <ContentHeader>Post-wedding Dimsum</ContentHeader>
                  <DetailContainer>
                    <Detail>
                      <DetailImage image={dimsum} />
                    </Detail>
                    <Detail style={{ maxWidth: '1100px' }}>
                      <DetailText>
                      What's the best way to cap off a weekend of celebrations? How about some greasy/salty/msg-laden (not to mention DELICIOUS) Chinese dimsum? We'll be organizing a post-wedding brunch for anyone who wants to join.<br/><br/>
                      Location is TBD but will be in Markham (likely in the <Anchor href={dimsumLocation} target="_blank">Kennedy Road/14th</Anchor> area) around 12:00pm on Sunday October 27.
                      </DetailText>
                    </Detail>
                  </DetailContainer>
                </Content>
                <Rule contentVisible={contentVisible} />
                <Content contentVisible={contentVisible}>
                  <ContentHeader>
                    Photos
                  </ContentHeader>
                  <DetailContainer>
                    <Detail>
                      <Photos>
                        {photos => (
                          <GridContainer>
                            {photos.sort((a,b) => a.sort - b.sort).map((p, i) => <ClickableImg src={p.thumb} key={i} height={300} width={300} style={{ margin: 0, padding: '5px' }} onClick={() => this.openLightbox(i)} />)}
                          </GridContainer>
                        )}
                      </Photos>
                    </Detail>
                  </DetailContainer>
                </Content>
              </ContentCenteredColumn>
              <RsvpContainer>
                <RsvpButton type="button" onClick={this.toggleRsvp}>RSVP</RsvpButton>
              </RsvpContainer>
            </Container>
            <ToastProvider>
              <ToastConsumer>
              {({ add }) => (
                <Dialog open={rsvpOpen} onClose={this.toggleRsvp} TransitionComponent={Transition as ComponentType<TransitionProps>} maxWidth='sm' fullWidth>
                  <DialogTitle>
                    RSVP
                  </DialogTitle>
                  <DialogContent dividers>
                    <DialogContentText>
                      Please enter your first and last name
                    </DialogContentText>
                    <form style={{ position: 'relative', marginBottom: '5px' }} onSubmit={this.searchRsvp}>
                      <TextField label='Search' value={search} fullWidth onChange={this.searchChanged} helperText={searchError} />
                      <a onClick={this.searchRsvp} style={{ position: 'absolute', right: 0, color: 'black', top: '15px', padding: '5px', cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={loading ? faCircleNotch : faSearch} spin={loading} />
                        </a>
                    </form>
                    { rsvp &&
                    <form style={{ padding: '20px 10px 10px 10px' }}>
                      <RsvpForm rsvp={rsvp} foodChanged={this.foodChanged} attendingRehearsalChanged={this.attendingRehearsalChanged} />
                      { rsvp.guests.map((g,i) => (
                        <GuestContainer key={g.id}>
                          <RsvpForm rsvp={g} foodChanged={this.foodChanged} attendingRehearsalChanged={this.attendingRehearsalChanged} />
                        </GuestContainer>))}
                        <TextField
                          label="Dietary Restrictions"
                          multiline
                          rowsMax="4"
                          value={rsvp.dietaryRestrictions}
                          onChange={this.dietaryRestrictionsChanged}
                          style={{ marginTop: '30px' }}
                          fullWidth
                        />
                    </form> }
                  </DialogContent>
                  <DialogActions>
                    <Button type="button" onClick={() => this.sendRsvp(add)} disabled={saving}>OK</Button>
                    <Button type="button" onClick={this.toggleRsvp} disabled={saving}>Cancel</Button>
                  </DialogActions>
                </Dialog>
              )}
              </ToastConsumer>
            </ToastProvider>
          </MuiThemeProvider>
        </Layout>
        <Photos>
          {photos => (
            <Lightbox
              images={photos.sort((a,b) => a.sort - b.sort).map(({ fluid, caption }, i) => ({ fluid, type: LightboxType.Jpeg, caption }))}
              open={lightboxOpen}
              currentImage={selectedPhotoIndex}
              withBackdrop
              onClose={this.closeLightbox} />
          )}
        </Photos>
      </>
    )
  }
}

const IndexWithGa = ({ location }) => <ReactGA pathname={location.pathname}><Index /></ReactGA>

export default IndexWithGa