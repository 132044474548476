import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { FluidObject, FixedObject } from "gatsby-image";

const captions = {
    '7.jpg': { caption: 'Whistler, January 2016: The only time when Jason\'s been allowed to kick Christy\'s butt at anything.', sort: 3 },
    '20.jpg': { caption: 'Jason napping. Pretty standard occurrence in this relationship.', sort: 11 },
    '16.jpg': { caption: 'The Wave in Arizona, July 2016: Christy commented that this would\'ve been a good spot to get engaged. The first time the "E" word was mentioned.', sort: 6 },
    '13.jpg': { caption: 'Brazil, September 2015: Yes, we\'re wearing matching Tilley hats.', sort: 2 },
    '18.jpg': { caption: 'Toronto Pearson Airport, October 2014: The trip that started it all. Bridesmaid and photographer Valerie Li famously commented that "the guy in the grey is standing too far apart". Jason didn\'t want to make it too obvious.', sort: 0 },
    '4.jpg': { caption: 'Norway, February 2016', sort: 4 },
    '6.jpg': { caption: 'Japan, September 2016: We <3 Japan', sort: 7 },
    '15.jpg': { caption: 'Utah, March 2016: Christy and Jason make it their goal to hit every US National Park. We\'re currently at 15/61. Come join us!', sort: 5 },
    '19.jpg': { caption: 'Peru, May 2017: Taking a short break while hiking the Inca trail. Photo cred: maid of honour Patty Ho.', sort: 8 },
    '12.jpg': { caption: 'Newfoundland, July 2017: Celebrating Canada.', sort: 9 },
    '2.jpg': { caption: 'Alaskan Cruise, June 2015: Christy & Jason\'s first ever solo vacation together.', sort: 1 },
    '8.jpg': { caption: 'New Zealand, January 2019: We\'re on top of the world!', sort: 10 }
}

function getCaptionAndSort(photo: string): { sort: number, caption: string} {
    if (captions[photo]) {
        return captions[photo]
    }

    return { sort: 999, caption: '' }
}

interface FluidImageResult extends FluidObject {
    presentationWidth: number;
    originalName?: string;
}

type PhotoQueryEdge = {
    node: {
        childImageSharp: {
            fixed: FixedObject,
            fluid: FluidImageResult
        }
    }
}

type PhotosQueryResult = {
    allFile: { edges: PhotoQueryEdge[] }
}

export interface Photo {
    fluid: FluidImageResult;
    caption?: string;
    sort: number;
    thumb: string;
}

const Photos = ({ children }: { children: (images: Photo[]) => JSX.Element }) => (
    <StaticQuery
        query={graphql`query GetPhotosQuery {
            allFile(filter: {extension: {regex: "/(jpg)/"}, relativeDirectory: {eq: "photos"}}) {
              edges {
                node {
                  name
                  relativePath
                  relativeDirectory
                  childImageSharp {
                    fixed(height: 400, width: 400) {
                        src
                    }
                    fluid(quality: 80, maxWidth: 2560) {
                        originalImg
                        src
                        sizes
                        srcSet
                        aspectRatio
                        presentationWidth
                        originalName
                    }
                  }
                }
              }
            }
          }`}
        render={(data: PhotosQueryResult) => children(data.allFile.edges.map(({ node: { childImageSharp: { fixed, fluid } } }) => ({ thumb: fixed.src, fluid, caption: getCaptionAndSort(fluid.originalName).caption, sort: getCaptionAndSort(fluid.originalName).sort })))} />)

export default Photos